import { version } from "./version";
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  version,
  // sandbox: false, // production
  sandbox: true, // mirror
  demoMode: false,
  production: true,
  firebase: {
    // Trust (Prod)
    apiKey: "AIzaSyAjMaTdEUf-acVb3lxsd6SJ8C1ocUEEYa0",
    authDomain: "trust-11b7d.firebaseapp.com",
    databaseURL: "https://trust-11b7d.firebaseio.com",
    projectId: "trust-11b7d",
    storageBucket: "trust-11b7d.appspot.com",
    messagingSenderId: "559041043219",
    appId: "1:559041043219:web:41504232bf1c679ad558e6",
    measurementId: "G-EDPLYFRDMM",
  },
  cloudFunctionsURL: "https://us-central1-trust-11b7d.cloudfunctions.net",
  // stripePrices: {
  //   anualOne: "https://buy.stripe.com/dR6dU1crTcVhd566oq",
  //   anualTwoFour: "https://buy.stripe.com/bIYaHP8bDcVhd56289",
  //   anualFive: "https://buy.stripe.com/dR69DL3VncVhghidQQ",
  // },
  stripePrices: {
    // test mode
    anualOne: "https://buy.stripe.com/test_fZecOP8C59To48MbIP",
    anualTwoFour: "https://buy.stripe.com/test_00g5mn19D4z434IaEM",
    anualFive: "https://buy.stripe.com/test_cN21673hLaXsaxaaEN",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
